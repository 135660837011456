"use client";
import { useMenu } from "@/contexts/menu";
import { Menu, Search } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

export const Header: React.FC = () => {
  return (
    <header role="banner" className="bg-white">
      <div className="xl:hidden">
        <MainMobile />
      </div>
      <div className="hidden xl:block">
        <MainDesktop />
      </div>
    </header>
  );
};

export const MainMobile: React.FC = () => {
  const { menuVisible, setMenuVisible } = useMenu();
  const pathname = usePathname();
  return (
    <>
      <div role="navigation" className="flex justify-between">
        <div className=" border-b border-b-brand-blue p-0 flex justify-center items-center">
          <Link
            href="/recherche"
            aria-label="Recherche"
            className="bg-brand-blue h-full w-14 flex justify-center items-center"
          >
            <Search className="text-white" />
          </Link>
        </div>
        <div className="flex items-center border-b flex-grow justify-center">
          <Link href="/">
            <Image
              alt="Logo Grand Paris Aménagement"
              src="/images/logo-color.svg"
              width={100}
              height={30}
            />
          </Link>
        </div>
        <div className=" border-b p-4 flex justify-center items-center">
          <button
            aria-label="Ouvrir le menu"
            onClick={() => setMenuVisible(!menuVisible)}
          >
            <Menu />
          </button>
        </div>
      </div>
      {pathname === "/" && (
        <ul className="flex text-xs justify-center p-3 space-x-2 text-brand-blue">
          <li>
            <Link href="/etablissement">L&apos;établissement</Link>
          </li>
          <li aria-hidden tabIndex={-1}>
            •
          </li>
          <li>
            <Link href="/operations">Carte des opérations</Link>
          </li>
          <li aria-hidden tabIndex={-1}>
            •
          </li>
          <li>
            <Link href="/publications">Publications</Link>
          </li>
        </ul>
      )}
    </>
  );
};

export const MainDesktop: React.FC = () => {
  return (
    <nav role="navigation" className="grid grid-cols-12 relative p-2">
      <div className="absolute top-0 h-full border-b border-b-brand-blue p-0 flex justify-center items-center">
        <Link
          href="/recherche"
          aria-label="Recherche"
          className="bg-brand-blue h-full w-14 flex justify-center items-center"
        >
          <Search className="text-white" />
        </Link>
      </div>
      <div className="col-span-5 flex justify-end items-center space-x-3 text-sm">
        <span className="font-semibold">Vous êtes :</span>
        <ul className="flex justify-start space-x-4 text-brand-blue">
          <li>
            <Link href="/?visitor=collectivite" className="hover:underline">
              Une collectivité
            </Link>
          </li>
          <li className="text-orange-500" aria-hidden tabIndex={-1}>
            •
          </li>
          <li>
            <Link href="/?visitor=journaliste" className="hover:underline">
              Journaliste
            </Link>
          </li>
          <li className="text-orange-500" aria-hidden tabIndex={-1}>
            •
          </li>
          <li>
            <Link href="/?visitor=candidat" className="hover:underline">
              Candidat
            </Link>
          </li>
          <li className="text-orange-500" aria-hidden tabIndex={-1}>
            •
          </li>
          <li>
            <Link href="/?visitor=riverain" className="hover:underline">
              Riverain
            </Link>
          </li>
        </ul>
      </div>
      <div className="col-span-2 flex justify-center items-center ">
        <Link href="/">
          <Image
            alt="Logo Grand Paris Aménagement"
            src="/images/logo-color.svg"
            width={140}
            height={40}
          />
        </Link>
      </div>
      <div className="col-span-5 flex justify-start items-center text-sm text-brand-blue">
        <ul className="flex justify-start space-x-4">
          <li>
            <Link className="hover:underline" href="/etablissement">
              L&apos;établissement
            </Link>
          </li>
          <li aria-hidden="true" className="text-gray-500">
            •
          </li>
          <li>
            <Link className="hover:underline" href="/operations">
              Carte des opérations
            </Link>
          </li>
          <li aria-hidden="true" className="text-gray-500">
            •
          </li>

          <li>
            <Link className="hover:underline" href="/publications">
              Publications
            </Link>
          </li>
          {/* <li aria-hidden="true" aria-label="" className="text-gray-500">
            •
          </li> */}
          {/* <li>
            <Link className="hover:underline" href="/publications">
              Nouvel item
            </Link>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};
