"use client";
import { IOperation } from "@/types/operation";
import Image from "next/image";
import { useRouter } from "next/navigation";

export const OperationMediumCard = ({ entry }: { entry: IOperation }) => {
  const router = useRouter();
  return (
    <div
      className="group h-full flex flex-col justify-between shadow-md hover:shadow-lg cursor-pointer rounded-sm overflow-hidden bg-white"
      onClick={() => {
        router.push(`/operations/${entry.slug}`);
      }}
    >
      <div>
        <div className="overflow-hidden aspect-square bg-gray-100">
          {entry.image ? (
            <Image
              src={`${entry.image.url}?w=600&h=600&fit=fill`}
              alt={entry.image.title ?? entry.title}
              loading="lazy"
              width={300}
              height={300}
              className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
            />
          ) : (
            <div>
              <div className="w-full h-full bg-gray-100 aspect-square flex items-center justify-center grayscale">
                <img
                  className="opacity-30"
                  src="/images/logo-color.svg"
                  alt="Grand Paris Aménagement"
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="p-3 pb-8">
            <h3 className="group-hover:underline text-lg font-semibold text-gray-700 group-hover:text-brand-blue line-clamp-4">
              {entry.title}
            </h3>
          </div>
        </div>
      </div>
      <div
        className=" 
      "
      >
        <div className="border-t p-3">
          <div className="line-clamp-1 text-xs font-medium">
            <span className="text-gray-500 font-normal">Territoire</span>{" "}
            <span className="text-brand-brown">
              {`${entry.direction ?? ``} - ${entry.city ?? ``}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
