import * as React from "react";
import { IProfile } from "@/types/profile";
import Image from "next/image";
import {
  // ProfileModal,
  ProfileModalLarge,
} from "../modal/profile";

export const ProfileCard: React.FC<{ profile: IProfile }> = ({ profile }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      {isOpen && (
        // <ProfileModal profile={profile} onClose={() => setIsOpen(false)} />
        <ProfileModalLarge profile={profile} onClose={() => setIsOpen(false)} />
      )}
      <div
        className="pb-4 shadow-xl rounded bg-chess-board bg-[length:80px_auto] cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <div className="">
          <div>
            {profile.image?.url ? (
              <Image
                src={`${profile.image?.url}?w=400&h=600&fit=fill`}
                alt={profile.firstname}
                width={400}
                height={600}
                className="w-full object-fill rounded-t"
              />
            ) : (
              <div className="w-full bg-white/90 h-full aspect-4/6"></div>
            )}
          </div>
          <h3 className="text-xl font-bold p-3 text-brand-blue bg-white">
            <span className="font-normal">{profile.firstname}</span>{" "}
            {profile.lastname}
          </h3>
        </div>
      </div>
    </>
  );
};

export const BoardProfileCard: React.FC<{ profile: IProfile }> = ({
  profile,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      {isOpen && (
        // <ProfileModal profile={profile} onClose={() => setIsOpen(false)} />
        <ProfileModalLarge profile={profile} onClose={() => setIsOpen(false)} />
      )}
      <div
        className="shadow-xl rounded cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <div className="">
          <div>
            {profile.image?.url ? (
              <Image
                src={`${profile.image?.url}?w=400&h=600&fit=fill`}
                alt={profile.firstname}
                width={400}
                height={600}
                className="w-full object-fill rounded-t"
              />
            ) : (
              <div className="w-full bg-white/90 h-full aspect-4/6"></div>
            )}
          </div>
          <div className=" font-bold p-4 text-brand-blue bg-white space-y-4">
            <h3 className="leading-tight">
              <span className="text-sm text-blue-900">{profile.firstname}</span>{" "}
              <span className="text-sm">{profile.lastname}</span>
            </h3>
            <p className="text-xs font-normal text-gray-800">
              {profile.position}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
