"use client";
import * as React from "react";
import { IBanner } from "@/types/banner";
import { ArrowRight, X } from "lucide-react";
import Image from "next/image";
import { gsap } from "gsap";
import { useLocalStorage } from "@/hooks/localStorage";
import Link from "next/link";

export const EventBanner = ({ entry }: { entry: IBanner }) => {
  const localStorageKey = `GpaEventBanner_${entry.sys.id}`;

  const [closed, setClosed] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const [storedValue, setValue] = useLocalStorage(localStorageKey, {
    shown: false,
  });

  React.useEffect(() => {
    if (closed) {
      gsap.to(".event-banner", {
        duration: 0.3,
        opacity: 0,
        height: 0,
        // padding: 0,
        onComplete: () => {
          setVisible(false);
          setValue({ shown: true });
        },
      });
    }
  }, [closed]);

  React.useEffect(() => {
    if (storedValue.shown) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [storedValue]);

  const handleClose = () => {
    setClosed(true);
  };
  const pathPrefix =
    entry.targetEntry?.__typename === "Post"
      ? "post"
      : entry.targetEntry?.__typename === "Page"
      ? "etablissement"
      : entry.targetEntry?.__typename === "Operation"
      ? "operations"
      : "";
  if (!visible) return null;
  return (
    <div className="event-banner bg-brand-blue w-[calc(100%+1px)] -ml-[1px] bg-line-pattern-white bg-[length:340px_340px] bg-repeat p-2 xl:p-32">
      <div className="bg-white grid grid-cols-12 rounded-sm shadow-md relative">
        <div className="absolute top-2 right-2 lg:-top-2 lg:-right-2 shadow-md">
          <button
            onClick={() => handleClose()}
            className="px-4 py-2 bg-brand-red text-white font-medium flex items-center gap-x-2 hover:bg-brand-blue.200"
          >
            <X />
            <span>Fermer</span>
          </button>
        </div>
        <div className="col-span-12 xl:col-span-4">
          <Image
            src={`${entry.image.url}?w=600&h=600&fit=fill`}
            alt={entry.title}
            width={800}
            height={800}
            className="aspect-square h-full w-full object-cover rounded-l-sm"
          />
        </div>
        <div className="col-span-12 xl:col-span-8">
          <div className="flex flex-col justify-center h-full">
            <div className="p-8 xl:p-20 space-y-10">
              <h2 className="text-2xl font-medium text-brand-blue">
                {entry.title}
              </h2>
              <p>{entry.description}</p>
              <div className="flex gap-x-3">
                {entry.targetEntry !== null && (
                  <div className="flex justify-start">
                    <Link
                      href={`/${pathPrefix}/${entry.targetEntry.slug}`}
                      title={entry.targetEntry.title}
                      className="px-3.5 py-2 bg-brand-red text-white flex items-center gap-x-2 hover:bg-brand-blue.200"
                    >
                      <span>Découvrir</span>
                      <ArrowRight className="w-4 h-4" />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
