"use client";
import { IPage } from "@/types/page";
import { IPost } from "@/types/post";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { linkResolver } from "@/utils/linkResolver";

export const VisitorTypeNav = ({
  collective,
  journalist,
  candidate,
  riverside,
}: {
  collective: IPage[] | IPost[];
  journalist: IPage[] | IPost[];
  candidate: IPage[] | IPost[];
  riverside: IPage[] | IPost[];
}) => {
  const searchParams = useSearchParams();
  const visitorFromQs = searchParams.get("visitor");
  const [data, setData] = useState<(IPage | IPost)[] | null>(null);
  const [visitorNiceName, setVisitorNiceName] = useState<string | null>(null);
  const router = useRouter();
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1280px)" });

  useEffect(() => {
    setData(
      visitorFromQs === "collectivite"
        ? collective
        : visitorFromQs === "journaliste"
        ? journalist
        : visitorFromQs === "candidat"
        ? candidate
        : visitorFromQs === "riverain"
        ? riverside
        : null
    );

    setVisitorNiceName(
      visitorFromQs === "collectivite"
        ? "une collectivité"
        : visitorFromQs === "journaliste"
        ? "un journaliste"
        : visitorFromQs === "candidat"
        ? "un candidat"
        : visitorFromQs === "riverain"
        ? "un riverain"
        : null
    );
  }, [visitorFromQs]);

  useEffect(() => {
    for (const [index, item] of itemsRef.current.entries()) {
      gsap.from(item, {
        duration: 0.35,
        ease: "power1.inOut",
        opacity: 0,
        x: -10,
        delay: index * 0.2,
      });
    }
  }, [data]);

  if (!data) return null;
  return (
    <div className="bg-brand-blue bg-line-pattern-white space-y-0 pt-10 px-0 xl:px-20 relative z-20 xl:w-[calc(100%+1px)] -ml-[1px]">
      <div className="px-4 xl:px-0">
        <div className="text-3xl xl:text-4xl font-bold text-brand-blue.100 mb-6">
          Vous êtes
          <span className="text-brand-red">
            {visitorNiceName ? ` ${visitorNiceName}` : null}
          </span>
        </div>
        <div className="flex">
          <div className="h-1 w-16 bg-brand-red" />
        </div>
      </div>

      {isTabletOrMobile && (
        <div className="translate-y-8">
          <Swiper
            slidesPerView={1.4}
            spaceBetween={16}
            wrapperClass="py-0"
            slidesOffsetAfter={16}
            slidesOffsetBefore={16}
            centeredSlides={false}
          >
            {data.map((item: IPage | IPost, index) => (
              <SwiperSlide
                key={`${item.sys.id}-carousel-item`}
                style={{ height: "auto" }}
                className="self-stretch h-auto bg-white shadow-xl group"
              >
                <div
                  ref={(el) => {
                    itemsRef.current[index] = el!;
                  }}
                  onClick={() => router.push(linkResolver(item))}
                  key={item.sys.id}
                  className="group rounded-sm cursor-pointer  "
                >
                  {item?.image && (
                    <img
                      src={`${item.image.url}?w=300&h=200&fit=fill`}
                      alt={item.image.title}
                      className="w-full h-48 object-cover"
                    />
                  )}
                  <div className="bg-white px-4 py-6">
                    <p className="group-hover:underline text-lg font-medium line-clamp-3">
                      {item.title ?? ``}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {!isTabletOrMobile && (
        <div className="grid xl:grid-cols-4 gap-12 translate-y-10">
          {data.map((item: IPage | IPost, index) => (
            <div
              ref={(el) => {
                itemsRef.current[index] = el!;
              }}
              onClick={() => router.push(linkResolver(item))}
              key={item.sys.id}
              className="group rounded-sm bg-white cursor-pointer shadow-xl group"
            >
              <div className="overflow-hidden">
                {item?.image && (
                  <img
                    src={`${item.image?.url}?w=300&h=200&fit=fill`}
                    alt={item.image?.title ?? "Grand Paris Aménagement"}
                    className="w-full h-48 object-cover group-hover:scale-105 transition-transform ease-in-out duration-300"
                  />
                )}
              </div>
              <div className="bg-white px-4 py-6">
                <p className="group-hover:underline text-lg font-medium line-clamp-3">
                  {item.title ?? ``}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
