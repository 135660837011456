import { ISocialNetworkLink, socialNetworkLinks } from "@/utils/socialNetworks";
import Link from "next/link";

export const SocialNetworkLogosMenu = () => (
  <ul className="flex justify-center space-x-4 p-4">
    {socialNetworkLinks.map((link: ISocialNetworkLink, i: number) => (
      <li key={`${link.url}-${i}`}>
        <Link target="_blank" href={link.url} className="block">
          <img
            src={`/images/logos/${link.logoPath}`}
            alt={link.name}
            aria-label={link.name}
            className="w-5 h-5 text-white block"
          />
        </Link>
      </li>
    ))}
  </ul>
);
