import * as React from "react";
import { gsap } from "gsap";
import { X } from "lucide-react";
import Image from "next/image";
import { IProfile } from "@/types/profile";
import { RichTextEntry } from "../richText/generic";
import ReactPlayer from "react-player";
import { createPortal } from "react-dom";
import { useMediaQuery } from "react-responsive";

interface ModalProps {
  profile: IProfile;
  onClose: () => void;
}

export const ProfileModal: React.FC<ModalProps> = ({ profile, onClose }) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (modalRef.current) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 0.75, ease: "power3.out" }
      );
    }
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30 !mt-0">
      <div
        ref={modalRef}
        className="bg-white xl:rounded-lg shadow-lg p-0 w-12/12 md:w-1/2 lg:w-1/3 xl:w-1/4 max-h-[100dvh] xl:max-h-[100vh] overflow-y-auto"
      >
        <div className="bg-brand-blue flex bg-line-pattern-white bg-[length:400px_400px] bg-repeat">
          <div className="shrink-0">
            <button onClick={onClose} className="p-4 text-white">
              <X />
            </button>
          </div>
        </div>
        <div>
          <div>
            <Image
              src={`${profile.image.url}?w=450&h=500&fit=fill`}
              alt={profile.image.title}
              width={450}
              height={500}
              className="w-full object-fill"
            />
          </div>
          <div className="p-8 space-y-4">
            <h3 className="text-2xl font-bold text-brand-blue">
              {profile.firstname}, {profile.lastname}
            </h3>
            <div>
              <p>{profile.position}</p>
            </div>
            <div>
              <h4 className="text-lg font-semibold text-brand-brown">
                Contact
              </h4>
              <p>{profile.postalAddress}</p>
              <p>{profile.email}</p>
            </div>
          </div>
        </div>
        {/* <div>React video player {videoId}</div> */}
      </div>
    </div>
  );
};

export const ProfileModalLarge: React.FC<ModalProps> = ({
  profile,
  onClose,
}) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (modalRef.current) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 0.75, ease: "power3.out" }
      );
    }
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30 !mt-0">
      <div
        ref={modalRef}
        className="bg-white xl:rounded-lg shadow-lg p-0 w-12/12 md:w-1/2 lg:w-1/3 xl:w-1/4 max-h-[100dvh] xl:max-h-[90dvh] overflow-y-auto"
      >
        <div className="bg-brand-blue flex bg-line-pattern-white bg-[length:400px_400px] bg-repeat">
          <div className="shrink-0">
            <button onClick={onClose} className="p-4 text-white">
              <X />
            </button>
          </div>
        </div>
        <div>
          <div>
            <Image
              src={`${profile.image.url}?w=450&h=500&fit=fill`}
              alt={profile.image.title}
              width={450}
              height={500}
              className="w-full object-fill"
            />
          </div>
          <div className="p-8 space-y-6">
            <h3 className="text-2xl font-bold text-brand-blue">
              {profile.firstname}, {profile.lastname}
            </h3>
            <div>
              <p>{profile.position}</p>
            </div>

            <div className="flex">
              <div className="min-w-xl w-[45px] h-1 bg-brand-red" />
            </div>
            {profile.body && (
              <div>
                <RichTextEntry
                  richTextDocument={profile.body.json}
                  links={null}
                />
              </div>
            )}
          </div>
        </div>

        {/* <div>React video player {videoId}</div> */}
      </div>
    </div>
  );
};

export const PortraitVideoModalLarge: React.FC<{
  videoUrl: string;
  onClose: () => void;
}> = ({ videoUrl, onClose }) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (modalRef.current) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 0.75, ease: "power3.out" }
      );
    }
  }, []);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return createPortal(
    <div className="fixed top-0 left-0 right-0 inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30 !mt-0">
      <div
        ref={modalRef}
        className="bg-white xl:rounded-lg shadow-lg p-0 w-12/12 md:w-1/2 lg:w-1/3 xl:w-[500px] max-h-[100dvh] xl:max-h-[90dvh] overflow-y-auto"
      >
        <div className="bg-brand-blue flex bg-line-pattern-white bg-[length:400px_400px] bg-repeat">
          <div className="shrink-0">
            <button onClick={onClose} className="p-4 text-white">
              <X />
            </button>
          </div>
        </div>
        <div>
          <div className=" space-y-6">
            <ReactPlayer
              url={videoUrl}
              controls={true}
              width={isDesktopOrLaptop ? 500 : `100vw`}
              height={isDesktopOrLaptop ? 500 : `100vw`}
              muted={false}
              // width="500px"
              // height="600px"
            />
          </div>
        </div>

        {/* <div>React video player {videoId}</div> */}
      </div>
    </div>,
    document.body
  );
};
