"use client";
import EventModal from "@/components/modal/event";
import { IEvent } from "@/types/event";
import { requestCmsEvent } from "@/utils/cmsClientQuery";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface AgendaContextProps {
  eventId: string | null;
  setEventId: (id: string) => void;
}

const AgendaContext = createContext<AgendaContextProps | undefined>(undefined);

export const AgendaProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const searchParams = useSearchParams();
  const eventIdFromQs = searchParams.get("event");

  const [eventId, setEventId] = useState<string | null>(eventIdFromQs ?? null);
  const [eventData, setEventData] = useState<any>(null);
  const router = useRouter();
  const pathname = usePathname();

  React.useEffect(() => {
    async function getEventData() {
      const data = (await requestCmsEvent(`
            query{
                eventCollection(where:{sys:{id:"${eventId}"}})
                {
                    items{
                        title
                        intro
                        slug
                        startDate
                        endDate
                        body{ json }
                        image{ url title } 
                    }
                }
            } 
    `)) as { eventCollection: { items: IEvent[] } };
      if (data) setEventData(data.eventCollection.items.pop() as IEvent);
    }
    if (eventId) {
      router.replace(`${pathname}?event=${eventId}`, { scroll: false });
      getEventData();
    } else {
      router.replace(`${pathname}`, { scroll: false });

      setEventData(null);
    }
  }, [eventId]);

  return (
    <AgendaContext.Provider value={{ eventId, setEventId }}>
      {/* <div>envent id {eventId}</div>
      <div>
        <pre>{JSON.stringify(eventData ?? null, null, 2)}</pre>
      </div> */}
      {eventData && (
        <EventModal entry={eventData} onClose={() => setEventId(null)} />
      )}
      {children}
    </AgendaContext.Provider>
  );
};

export const useAgenda = (): AgendaContextProps => {
  const context = useContext(AgendaContext);
  if (!context) {
    throw new Error("useAgenda must be used within an AgendaProvider");
  }
  return context;
};
