"use client";
import { useAgenda } from "@/contexts/agenda";
import { IEvent } from "@/types/event";
import dayjs from "dayjs";
import { ArrowRight, Dot } from "lucide-react";

export const EventSmallCard = ({ entry }: { entry: IEvent }) => {
  const { setEventId } = useAgenda();
  return (
    <div
      onClick={() => setEventId(entry.sys.id)}
      className="grid grid-cols-12 gap-4 xl:gap-8 group cursor-pointer border border-white/40 rounded-sm py-8 px-4 relative hover:border-white/60"
    >
      <Dot
        size="32"
        className="absolute left-0 -translate-x-4 top-6 bg-gray-900"
      />

      <div className="col-span-8 space-y-4 relative">
        <div className="flex">
          <p className="uppercase text-xs font-medium tracking-wide border-b-2 border-b-gray-600 flex space-x-2 items-center">
            {/* {entry.contentfulMetadata.tags.map((tag) => tag.name).join(", ")} */}
            <span>{dayjs(entry.startDate).format("DD/MM/YYYY")}</span>
            {entry.endDate && (
              <>
                <ArrowRight size={12} />
                <span>{dayjs(entry.endDate).format("DD/MM/YYYY")}</span>
              </>
            )}
          </p>
        </div>
        <div className="line-clamp-3">
          <h3
            className="font-light block group-hover:underline"
            onClick={() => setEventId(entry.sys.id)}
          >
            {entry.title}
          </h3>
        </div>
      </div>
    </div>
  );
};
