export const CorpoServicesList: React.FC = () => {
  const items = [
    {
      icon: "amenagement.svg",
      text: `Aménagement de territoires en zones urbaines et rurales`,
    },
    {
      icon: "requalification.svg",
      text: `Requalification d'activité économique`,
    },
    {
      icon: "etudes-urbaines.svg",
      text: `Études urbaines`,
    },
    {
      icon: "developpement-economique.svg",
      text: `Développement économique et territorial`,
    },
    {
      icon: "renovation-urbaine.svg",
      text: `Rénovation urbaine`,
    },
    {
      icon: "requalification.svg",
      text: `Requalification de l'habitat privé dégradé`,
    },
    {
      icon: "foncier.svg",
      text: `Stratégie foncière en appui à des projets identifiés`,
    },
    {
      icon: "renaturation.svg",
      text: `Renaturation, protection des espaces naturels, agricoles ou forestiers`,
    },
    {
      icon: "quartier-de-gare.svg",
      text: `Aménagement de quartiers de gare`,
    },
    {
      icon: "pavillons.svg",
      text: `Accompagnement de la mutation des tissus pavillonnaires`,
    },
    {
      icon: "foncier.svg",
      text: `Accompagnement des collectivités dans leurs relations avec les grands acteurs économiques`,
    },
    {
      icon: "centre-ville.svg",
      text: `Revitalisation de centre-ville`,
    },
    {
      icon: "micro-amenagement.svg",
      text: `Micro aménagement urbain`,
    },
    {
      icon: "foncier.svg",
      text: `Gestion foncière pour compte de tiers`,
    },
  ];
  return (
    <div className="grid xl:grid-cols-2  gap-4">
      {items.map((item, index: number) => (
        <div
          key={`service-item-index-${index}`}
          className="flex items-center space-x-4 bg-blue-50 p-4"
        >
          <img
            src={`/images/pictos/${item.icon}`}
            alt="Pictogramme"
            className="w-12 h-12"
            key={`${item.icon}-${index}`}
          />
          <p>{item.text}</p>
        </div>
      ))}
    </div>
  );
};
