"use client";
import { usePathname } from "next/navigation";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface MenuContextProps {
  menuVisible: boolean;
  setMenuVisible: (visible: boolean) => void;
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined);

export const MenuProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const pathname = usePathname();
  React.useEffect(() => {
    setMenuVisible(false);
  }, [pathname]);
  return (
    <MenuContext.Provider value={{ menuVisible, setMenuVisible }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = (): MenuContextProps => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenu must be used within a MenuProvider");
  }
  return context;
};
