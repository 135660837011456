"use client";
import * as React from "react";
import { IProfile } from "@/types/profile";
import clsx from "clsx";
import Image from "next/image";
import { RichTextEntry } from "../richText/generic";
import { Video } from "lucide-react";
import VideoModal from "../modal/video";

export const HrProfilesList = ({ profiles }: { profiles: IProfile[] }) => {
  const data = profiles.filter((profile: IProfile) => profile.isHrPageProfile);
  return (
    <div className="space-y-6 xl:space-y-20 relative">
      {data.map((profile: IProfile, index: number) => (
        <HrProfileItem
          key={profile.sys.id}
          profile={profile}
          odd={index % 2 === 0}
        />
      ))}
      <div className="absolute top-0 left-0 w-full h-full justify-center hidden xl:flex">
        <div className="bg-gray-100 h-full w-[1px]" />
      </div>
    </div>
  );
};

const HrProfileItem = ({
  profile,
  odd,
}: {
  profile: IProfile;
  odd: boolean;
}) => {
  const [isVideoOpen, setIsVideoOpen] = React.useState<boolean>(false);
  return (
    <>
      {isVideoOpen && (
        <VideoModal
          videoId={profile.youtubeUrl ?? ``}
          onClose={() => setIsVideoOpen(false)}
        />
      )}
      <div className="grid xl:grid-cols-2 gap-8 xl:gap-20 border-b xl:border-b-0 border-gray-200 pb-8 relative z-10">
        <div
          className={clsx("xl:order-2 flex items-center", odd && "xl:order-1")}
        >
          <div className="space-y-6">
            <h3 className="text-2xl font-bold text-brand-blue">
              {profile.firstname}, {profile.position.toLocaleLowerCase()}
            </h3>
            <RichTextEntry
              richTextDocument={profile.bodySecondary.json}
              links={null}
            />
            <div>
              <button
                onClick={() => setIsVideoOpen(true)}
                className="bg-brand-red text-white px-3 py-1 flex gap-x-2 items-center rounded-xs hover:bg-brand-blue.200 transition-all duration-150"
              >
                <Video className="w-4 h-4" />
                <span>Voir la vidéo</span>
              </button>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            "hidden xl:block xl:order-1 relative",
            odd && "xl:order-2"
          )}
        >
          <div
            className={clsx(
              "absolute top-16 w-20 h-20 z-0",
              !odd ? `-right-10` : `-left-10 `
            )}
          >
            <div
              className="w-20 h-20 rounded-full border border-gray-100"
              style={{
                //   clipPath: "circle(50% at 50% 50%)",
                clipPath: odd ? "inset(50% 50% 0 0%)" : "inset(50% 0  0 50% )",
              }}
            />
          </div>
          {profile.imageSecondary && (
            <Image
              onClick={() => setIsVideoOpen(true)}
              className="border-4 border-brand-red cursor-pointer"
              src={profile.imageSecondary.url}
              alt={profile.imageSecondary.title}
              width={profile.imageSecondary.width}
              height={profile.imageSecondary.height}
            />
          )}
        </div>
      </div>
    </>
  );
};
