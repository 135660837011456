"use client";
import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Image from "next/image"; // Si tu utilises Next.js pour l'optimisation des images
import Link from "next/link";
import { ArrowRight, Dot, File, MoveDown } from "lucide-react";
import {
  PartnerLogosListPrimary,
  PartnerLogosListSecondary,
} from "./shortcodes/partnerLogosList";
import { DurableStatsList, StatsList } from "./shortcodes/statsList";
import { stringToSlug } from "@/utils/slug";
import { IProfile } from "@/types/profile";
import { HrProfilesList } from "../list/hrProfilesList";
import { CorpoServicesList } from "../list/corpoServicesList";
import { ExpertsProfilesList } from "../list/expertsProfilesList";
import { ProfilesGridList } from "../list/profilesGridList";
import ReactPlayer from "react-player";
// import { YoutubeVideoIframe } from "../player/youtube";

interface RichTextProps {
  richTextDocument: any; // Typiquement un JSON venant de Contentful
  profiles?: IProfile[];
  links: any;
}

const TallyFormButton: React.FC<{ tallyId: string; anchorText: string }> = ({
  tallyId,
  anchorText,
}) => {
  return (
    <button
      data-tally-open={tallyId}
      data-tally-layout="modal"
      data-tally-hide-title="1"
      className="bg-green-fluo text-black/90 font-bold py-2 px-4 rounded-xs hover:underline"
    >
      {anchorText}
    </button>
  );
};

export const RichTextEntry: React.FC<RichTextProps> = ({
  richTextDocument,
  profiles,
  links,
}) => {
  // console.log("links", links);

  const findEntryDetails = (entryId: string) => {
    // console.log("entryId", entryId);
    // console.log("links", links);
    return links?.entries?.hyperlink.find(
      (entry: any) => entry?.sys?.id === entryId
    );
  };

  const findAssetDetails = (assetId: string) => {
    return links.assets.block.find((asset: any) => asset.sys.id === assetId);
  };

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
        const text = children?.[0] || "";

        // TALLY FORM SHORTCODE
        const tallyShortcodeRegex =
          /\[TallyFormLink tallyId="(.+?)" anchorText="(.+?)" \/\]/;
        const matchTally = tallyShortcodeRegex.exec(text);

        if (matchTally) {
          const tallyId = matchTally[1]; // Capture la valeur de tallyId
          const anchorText = matchTally[2]; // Capture la valeur de anchorText
          return (
            <div className="my-4">
              <TallyFormButton tallyId={tallyId} anchorText={anchorText} />
            </div>
          );
        }

        const matchServiceList = /\[GpaServicesList\]/.exec(text);
        if (matchServiceList) {
          return (
            <div className="my-4">
              <CorpoServicesList />
            </div>
          );
        }
        const matchExpertsList = /\[GpaExpertsProfilesList\]/.exec(text);
        if (matchExpertsList) {
          return (
            <div className="my-4">
              {profiles && <ExpertsProfilesList profiles={profiles} />}
            </div>
          );
        }

        const matchPartnerLogoPrimaryList = /\[LogosList\]/.exec(text);
        if (matchPartnerLogoPrimaryList) {
          return (
            <div className="my-8">
              <PartnerLogosListPrimary />
            </div>
          );
        }

        const matchPartnerLogoSecondaryList = /\[PartnersLogosList\]/.exec(
          text
        );
        if (matchPartnerLogoSecondaryList) {
          return (
            <div className="my-4">
              <PartnerLogosListSecondary />
            </div>
          );
        }

        const matchStatsList = /\[StatsList\]/.exec(text);
        if (matchStatsList) {
          return (
            <div className="my-4">
              <StatsList />
            </div>
          );
        }

        const matchDurableStatsList = /\[DurableStatsList\]/.exec(text);
        if (matchDurableStatsList) {
          return (
            <div className="my-4">
              <DurableStatsList />
            </div>
          );
        }

        const matchPodcast = /\[Podcast id="(.+?)"\]/.exec(text);
        if (matchPodcast) {
          return (
            <div className="py-0">
              <iframe
                className="w-full h-[500px] xl:h-64"
                src={`https://player.ausha.co/index.html?podcastId=${matchPodcast[1]}&playlist=false&color=%2372238e&v=3&playerId=ausha-NIDL`}
              />
            </div>
          );
        }

        const matchHrProfilesList = /\[HrProfilesList\]/.exec(text);
        if (matchHrProfilesList) {
          return (
            <div className="py-0">
              {profiles && <HrProfilesList profiles={profiles} />}
            </div>
          );
        }

        const matchGpaManagementCommittee = /\[GpaManagementCommittee\]/.exec(
          text
        );
        if (matchGpaManagementCommittee) {
          return (
            <div className="py-0">
              {profiles && (
                <ProfilesGridList
                  profiles={profiles.filter(
                    (profile: IProfile) => profile.codir === true
                  )}
                />
              )}
            </div>
          );
        }

        // VIDEO STANDALONE PLAYER SHORTCODE
        const videoRegex = /\[Video url="(.+?)"]/;
        const matchVideo = videoRegex.exec(text);

        if (matchVideo) {
          const videoUrl = matchVideo[1]; // Capture la valeur de tallyId
          return (
            <div className="bg-gray-50 p-0 rounded-sm mb-8">
              <div className="aspect-square w-full xl:w-1/2 mx-auto ">
                <ReactPlayer
                  url={videoUrl}
                  controls={true}
                  width={"100%"}
                  height={"100%"}
                />
                {/* <TallyFormButton tallyId={tallyId} anchorText={anchorText} /> */}
                {/* <YoutubeVideoIframe videoId={youtubeId} title={"Player"} /> */}
              </div>
            </div>
          );
        }

        // YOUTUBE PLAYER SHORTCODE
        const youtubeShortcodeRegex = /\[YoutubePlayer youtubeId="(.+?)" \/\]/;
        const matchYoutube = youtubeShortcodeRegex.exec(text);

        if (matchYoutube) {
          // const youtubeId = matchYoutube[1]; // Capture la valeur de tallyId
          return (
            <div className="my-4 font-li">
              {/* <TallyFormButton tallyId={tallyId} anchorText={anchorText} /> */}
              {/* <YoutubeVideoIframe videoId={youtubeId} title={"Player"} /> */}
            </div>
          );
        }

        // Si aucun shortcode n'est trouvé, retourne le contenu par défaut
        return <p className="text-base font-light mb-6">{children}</p>;
      },
      [BLOCKS.HEADING_1]: (node: any, children: any) => (
        <h1 className="text-3xl uppercase font-bold mb-4">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node: any, children: any) => (
        <div id={stringToSlug(`${children[0]}`)} className="relative">
          <h2 className="text-2xl text-brand-blue font-bold mb-4 mt-10 first:mt-0">
            {children}
          </h2>
          <div className="flex flex-col absolute top-3 -left-20 items-center xl:translate-x-[-1.5px]">
            <Dot size={34} className="text-brand-red" />
            <MoveDown size={34} strokeWidth={1.3} />
          </div>
        </div>
      ),
      [BLOCKS.HEADING_3]: (node: any, children: any) => (
        <h3 className="text-xl mt-8 font-bold mb-4">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node: any, children: any) => (
        <h3 className="text-lg mt-6 font-semibold mb-4">{children}</h3>
      ),
      [BLOCKS.UL_LIST]: (node: any, children: any) => (
        <ul
          style={{ listStyleType: "circle" }}
          className="ml-0 mb-4 space-y-2 !text-xs"
        >
          {children}
        </ul>
      ),

      // [BLOCKS.UL_LIST]: (node, children) => <List spacing={{ base: 2, lg: 6 }}>{children}</List>,
      [BLOCKS.LIST_ITEM]: (node: any, children: any) => (
        <li className="flex space-x-2">
          <div>
            <ArrowRight size="18" className="text-brand-red mt-1" />
          </div>
          {children}
        </li>
      ),
      [BLOCKS.OL_LIST]: (node: any, children: any) => (
        <ol className="list-decimal ml-5 mb-4">{children}</ol>
      ),
      [BLOCKS.QUOTE]: (node: any, children: any) => (
        <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
          {children}
        </blockquote>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const assetId = node.data.target.sys.id;
        const asset = findAssetDetails(assetId);

        // return <pre>{JSON.stringify(asset, null, 2)}</pre>;

        if (!asset) return null;
        if (asset.contentType === "application/pdf") {
          return (
            <a
              className="text-sm py-2 px-4 mb-3 -mt-2 bg-gray-50 border border-brand-red rounded-sm text-gray-600 flex items-center justify-between gap-x-3 shadow-sm hover:underline"
              href={asset.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>{asset.title}</div>
              <div>
                <File />
              </div>
            </a>
          );
        }
        return (
          <div className="my-6">
            <Image
              src={`${asset.url}`}
              alt={asset.title || "Embedded Image"}
              width={800}
              height={400}
              className="mx-auto w-full"
            />
          </div>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
        const entryId = node.data.target.sys.id;
        const entry = findEntryDetails(entryId);

        // console.log("entry", entry);

        const link = () => {
          switch (entry.__typename) {
            case "Post":
              return `/post/${entry.sys.id}`;
            case "Page":
              return `/etablissement/${entry?.slug}`;
            case "Operation":
              return `/operations/${entry?.slug}`;
            default:
              return "/";
          }
        };

        if (!entry) {
          return <span>{children}</span>; // Si l'entrée n'existe pas, afficher le texte sans lien
        }

        if (typeof children[0] === "string" && children[0].startsWith(">>")) {
          return (
            <span className="flex mt-2">
              <Link
                className="bg-brand-red text-white px-3 py-1.5 flex items-center gap-x-2 rounded-xs hover:bg-brand-blue.200 transition-all duration-150"
                href={link()}
              >
                <ArrowRight className="w-3 h-3" />
                <span>{children[0].slice(2)}</span>
              </Link>
            </span>
          );
        }

        if (typeof children[0] === "string" && children[0].startsWith(">")) {
          return (
            <span className="flex">
              <Link
                className="border border-brand-red text-brand-red px-3 py-1.5 flex items-center gap-x-2 rounded-xs hover:bg-brand-red hover:text-white transition-all duration-150"
                href={link()}
              >
                <ArrowRight className="w-3 h-3" />
                <span>{children[0].slice(1)}</span>
              </Link>
            </span>
          );
        }

        return (
          <Link className="underline" href={link()}>
            {children}
          </Link>
        );
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        const uri = node.data?.uri;
        const isExternalLink =
          uri.startsWith("http") && !uri.includes("grandparisamenagement.fr");
        const convertInternalLink = (uri: string) =>
          uri.includes("https://www.grandparisamenagement.fr/")
            ? uri.replace("https://www.grandparisamenagement.fr/", "/")
            : uri;

        if (typeof children[0] === "string" && children[0].startsWith(">>")) {
          return (
            <span className="flex mt-4">
              <Link
                className="bg-brand-red text-white px-3 py-1.5 flex items-center gap-x-2 rounded-xs hover:bg-brand-blue.200 transition-all duration-150"
                href={convertInternalLink(uri)}
                target={isExternalLink ? "_blank" : "_self"}
              >
                <ArrowRight className="w-3 h-3" />
                <span>{children[0].slice(2)}</span>
              </Link>
            </span>
          );
        }

        if (typeof children[0] === "string" && children[0].startsWith(">")) {
          return (
            <span className="flex">
              <Link
                className="border border-brand-red text-brand-red px-3 py-1.5 flex items-center gap-x-2 rounded-xs hover:bg-brand-red hover:text-white transition-all duration-150"
                href={convertInternalLink(uri)}
                target={isExternalLink ? "_blank" : "_self"}
              >
                <ArrowRight className="w-3 h-3" />
                <span>{children[0].slice(1)}</span>
              </Link>
            </span>
          );
        }

        return (
          <Link
            className="underline"
            href={convertInternalLink(uri)}
            target={isExternalLink ? "_blank" : "_self"}
          >
            {children}
          </Link>
        );
      },
    },
  };

  return documentToReactComponents(richTextDocument, options);
};
