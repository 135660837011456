"use client";
import { gql, GraphQLClient } from "graphql-request";
const endpoint = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CF_SPACE_ID}`;
const client = new GraphQLClient(endpoint, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_CF_PREVIEW_ACCESS_TOKEN}`,
  },
});

export const requestCmsData = async (query: string) => {
  const cmsQuery = gql`
    ${query}
  `;
  const data: { postCollection: { total: number } } = await client.request(
    cmsQuery
  );
  return data;
};

export const requestCmsEvent = async (query: string) => {
  const cmsQuery = gql`
    ${query}
  `;
  const data = await client.request(cmsQuery);
  return data;
};
