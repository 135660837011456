import { IEvent } from "@/types/event";
import * as React from "react";
import { gsap } from "gsap";
import { RichTextEntry } from "../richText/generic";
import dayjs from "dayjs";
import { X } from "lucide-react";

interface ModalProps {
  entry: IEvent;
  onClose: () => void;
}

const EventModal: React.FC<ModalProps> = ({ entry, onClose }) => {
  //   if (!entry) return null;

  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (modalRef.current) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 0.75, ease: "power3.out" }
      );
    }
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
      <div
        ref={modalRef}
        className="bg-white xl:rounded-lg shadow-lg p-0 w-12/12 md:w-1/2 lg:w-1/3 max-h-[100dvh] xl:max-h-[90vh] overflow-y-auto"
      >
        <div className="bg-brand-blue bg-line-pattern flex">
          <div className="shrink-0">
            <button onClick={onClose} className="p-4 text-white">
              <X />
            </button>
          </div>
          {entry.image && (
            <div>
              <img
                src={`${entry.image.url}?w=800&h=600&fit=fill`}
                alt={entry.image.title ?? entry.title}
                className="w-full h-full object-cover"
              />
            </div>
          )}
        </div>
        <div className="space-y-8 px-12 py-20 bg-line-pattern">
          <div className="text-sm text-gray-500">
            {entry.startDate && entry.endDate && (
              <p>
                Du {dayjs(entry.startDate).format(`DD/MM/YYYY`)} au{" "}
                {dayjs(entry.endDate).format(`DD/MM/YYYY`)}
              </p>
            )}
            {entry.startDate && !entry.endDate && (
              <p>Le {dayjs(entry.startDate).format(`DD/MM/YYYY`)}</p>
            )}
          </div>
          <h4 className="text-3xl font-medium text-brand-blue">
            {entry.title}
          </h4>
          <div className="flex">
            <div className="w-8 h-1 bg-brand-red" />
          </div>
          <p className="font-medium">{entry.intro}</p>
          <RichTextEntry richTextDocument={entry.body.json} links={null} />
        </div>
      </div>
    </div>
  );
};

export default EventModal;
