"use client";
import clsx from "clsx";
import { ArrowDown } from "lucide-react";
import { useRouter } from "next/navigation";

interface IHomepageCorpoArgument {
  label: string;
  text: string;
  url: string;
  color: "blue" | "green" | "red";
}
export const HomeCorpoArguments = () => {
  const data: IHomepageCorpoArgument[] = [
    {
      text: `Nous sommes un groupement d'aménageurs au service des maires et des collectivités.`,
      label: `Notre organisation et nos filiales`,
      url: `/etablissement`,
      color: `blue`,
    },
    {
      text: `Nous intervenons sur des opérations de toutes tailles et de toutes complexités.`,
      label: `Nos expertises et nos offres`,
      url: `/etablissement/nos-expertises`,
      color: `green`,
    },
    {
      text: `Avec l'ambition de (re)créer de la qualité de vie pour les habitants, et pour longtemps.`,
      label: `Notre mission de service public`,
      url: `/etablissement/notre-ambition`,
      color: `red`,
    },
  ];
  const router = useRouter();
  return (
    <div className="grid xl:grid-cols-3 py-16 gap-y-10">
      {data.map((item: IHomepageCorpoArgument, index: number) => (
        <div
          onClick={() => router.push(item.url)}
          className={clsx(
            "text-center space-y-6 group cursor-pointer flex flex-col justify-between",
            item.color === `blue`
              ? `text-brand-blue`
              : item.color === `green`
              ? `text-brand-green`
              : item.color === `red`
              ? `text-brand-red`
              : ``
          )}
          key={`HomeCorpoArguments-${index}`}
        >
          <div>
            <div className="flex justify-center items-center relative mb-8 h-12">
              <div className="absolute">
                <div
                  className={clsx(
                    "border rounded-full",
                    item.color === `blue`
                      ? `border-brand-blue`
                      : item.color === `green`
                      ? `border-brand-green`
                      : item.color === `red`
                      ? `border-brand-red`
                      : ``
                  )}
                >
                  <div
                    className={clsx(
                      " w-3 h-3 m-3 rounded-full group-hover:m-4 transition-all duration-300 ease-in-out",
                      item.color === `blue`
                        ? `bg-brand-blue`
                        : item.color === `green`
                        ? `bg-brand-green`
                        : item.color === `red`
                        ? `bg-brand-red`
                        : ``
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              className={clsx(
                `px-8 font-medium`,
                index < 2 ? `xl:border-r` : ``
              )}
            >
              {item.text}
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex justify-center items-center group-hover:translate-y-2 transition-transform duration-300 ease-in-out">
              <ArrowDown />
            </div>
            <div>
              <span
                className={clsx(
                  "border-b pb-1",
                  item.color === `blue`
                    ? `border-b-brand-blue`
                    : item.color === `green`
                    ? `border-b-brand-green`
                    : item.color === `red`
                    ? `border-b-brand-red`
                    : ``
                )}
              >
                {item.label}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
