"use client";
import React from "react";
import { useMenu } from "@/contexts/menu";
import clsx from "clsx";
import { Search, X } from "lucide-react";
import { SocialNetworkLogosMenu } from "./socialNetworkLogosMenu";
import { MobileMenuAccordion } from "./mobileMenuAccordion";
import Link from "next/link";
import { useMediaQuery } from "react-responsive";
import { createPortal } from "react-dom";

export const MainMenu: React.FC = () => {
  const { menuVisible, setMenuVisible } = useMenu();
  const [renderMobileNav, setRenderMobileNav] = React.useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1280px)" });

  React.useEffect(() => {
    setRenderMobileNav(isTabletOrMobile);
  }, [isTabletOrMobile]);

  return (
    renderMobileNav &&
    createPortal(
      <nav
        className={clsx(
          "fixed top-0 left-0 w-screen h-screen transition-all duration-200 ease-in-out bg-gray-950 flex flex-col justify-between",
          !menuVisible
            ? "translate-x-full opacity-0"
            : "translate-x-0 opacity-100 z-40"
        )}
      >
        <div className="flex justify-between items-center p-0 text-white border-b border-b-white/10">
          <div className="border-r border-r-white/10">
            <Link
              href="/recherche"
              className="p-4 block"
              aria-label="Recherche"
            >
              <Search />
            </Link>
          </div>
          <div>
            <img
              alt="Logo Grand Paris Aménagement"
              src="/images/logo-white.svg"
              width={100}
              height={30}
              loading="lazy"
            />
          </div>
          <div className="border-l border-l-white/10">
            <button
              aria-label="Ouvrir le menu mobile"
              className="p-4"
              onClick={() => setMenuVisible(false)}
            >
              <X className="text-white" />
            </button>
          </div>
        </div>
        <MobileMenuAccordion />
        <div className="text-white">
          <SocialNetworkLogosMenu />
        </div>
      </nav>,
      document.body
    )
  );
};
