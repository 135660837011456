"use client";
import * as React from "react";
import { gql, GraphQLClient } from "graphql-request";
import { IEvent } from "@/types/event";

import { EventSmallCard } from "../card/eventSmallCard";
import { AgendaProvider } from "@/contexts/agenda";
import { useInView } from "react-intersection-observer";

const endpoint = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CF_SPACE_ID}`;
const client = new GraphQLClient(endpoint, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_CF_PREVIEW_ACCESS_TOKEN}`,
  },
});
export const FooterAgenda = () => {
  const [entries, setEntries] = React.useState<IEvent[]>([]);
  const { ref, inView } = useInView({ trackVisibility: true, delay: 100 });

  React.useEffect(() => {
    async function getCmsData() {
      const query = gql`
        {
          eventCollection(limit: 2) {
            total
            items {
              __typename
              sys {
                id
                publishedAt
              }
              title
              slug
              startDate
              endDate
              image {
                url
                width
                height
                title
              }
            }
          }
        }
      `;
      const data: { eventCollection: { total: number; items: IEvent[] } } =
        await client.request(query);
      setEntries(data.eventCollection.items);
    }
    if (!entries.length && inView) getCmsData();
  }, [entries, inView]);

  return (
    <div>
      <React.Suspense fallback={<div>Loading...</div>}>
        <AgendaProvider>
          <div ref={ref} />
          {/* <pre>{JSON.stringify(inView, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(entries, null, 2)}</pre> */}
          <div className="grid-cols-2 gap-4 grid">
            {entries?.map((entry: IEvent) => (
              <EventSmallCard key={entry.sys.id} entry={entry} />
            ))}
          </div>
        </AgendaProvider>
      </React.Suspense>
    </div>
  );
};
