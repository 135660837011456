"use client";
import { useMenu } from "@/contexts/menu";
import clsx from "clsx";
import { ChevronRight, Minus, Plus } from "lucide-react";
import Link from "next/link";
import * as React from "react";

interface ILink {
  label: string;
  href: string;
}

export const MobileMenuAccordion: React.FC = ({}) => {
  const [focusGroup, setFocusGroup] = React.useState<number | null>(null);
  const { setMenuVisible } = useMenu();
  const links1 = [
    {
      groupTitle: "Vous êtes",
      links: [
        {
          label: "Une collectivité",
          href: "/?visitor=collectivite",
        },
        {
          label: "Journaliste",
          href: "/?visitor=journaliste",
        },
        {
          label: "Candidat",
          href: "/?visitor=candidat",
        },
        {
          label: "Un riverain",
          href: "/?visitor=riverain",
        },
      ],
    },
    {
      groupTitle: "Grand Paris Aménagement",
      links: [
        {
          label: "L'établissement",
          href: "/etablissement",
        },
        {
          label: "Nos expertises",
          href: "/etablissement/expertises",
        },
        {
          label: "Nos directions fonctionnelles",
          href: "/etablissement/nos-directions-fonctionnelles",
        },
        {
          label: "Notre ambition",
          href: "/etablissement/notre-ambition",
        },
        {
          label: "Un aménageur durable",
          href: "/etablissement/un-amenageur-durable",
        },
        {
          label: "#Cestundétailpourvous",
          href: "etablissement/un-detail-pour-vous",
        },
        {
          label: "Nos offres d’emploi",
          href: "/etablissement/nos-offres",
        },
      ],
    },
  ];

  const links2 = [
    {
      label: "Nos opérations",
      href: "/operations",
    },
    {
      label: "Nos actualités",
      href: "/publications",
    },
    {
      label: "Notre agenda",
      href: "/agenda",
    },
    {
      label: "Nous contacter",
      href: "/etablissement/nous-contacter",
    },
  ] as ILink[];

  return (
    <>
      <div className="p-4 text-white">
        {/* focus {focusGroup} */}
        <div className="text-white space-y-0">
          {links1.map((group, i) => (
            <div
              key={i}
              className={clsx(
                "transition-all duration-1000 ease-in-out",
                i === focusGroup && "bg-white/10 px-2 rounded-sm"
              )}
            >
              <button
                onClick={() => setFocusGroup(i === focusGroup ? null : i)}
                className="font-light w-full text-xl py-2 flex justify-between items-center border-b border-b-white/10"
              >
                <span>{group.groupTitle}</span>
                <span>
                  {i === focusGroup && <Minus strokeWidth={1.55} />}
                  {i !== focusGroup && <Plus strokeWidth={1.55} />}
                </span>
              </button>
              <div
                className={clsx(
                  "space-y-2  grid transition-all duration-500 ",
                  i === focusGroup ? "grid-rows-[1fr] py-2" : "grid-rows-[0fr]"
                )}
              >
                <div className="overflow-hidden">
                  {group.links.map((link, j) => (
                    <Link
                      onClick={() => setMenuVisible(false)}
                      className="block text-sm px-2 py-1 text-white/70"
                      key={j}
                      href={link.href}
                    >
                      {link.label}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          ))}
          {links2.map((link: ILink, i: number) => (
            <Link
              className={clsx(
                "font-light text-xl py-2 flex justify-between items-center",
                i + 1 < links2.length && "border-b border-b-white/10"
              )}
              key={i}
              href={link.href}
            >
              <span>{link.label}</span>
              <span>
                <ChevronRight strokeWidth={1.55} />
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};
