"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import { OperationMediumCard } from "../card/operationMediumCard";
import { IOperation } from "@/types/operation";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
export const HomeOperationsCarousel = ({
  operations,
}: {
  operations: IOperation[];
}) => (
  <div className="xl:hidden w-screen -ml-6 _border _border-red-600 overflow-visible">
    <Swiper
      slidesPerView={1.4}
      spaceBetween={16}
      wrapperClass="py-2"
      slidesOffsetAfter={18}
      slidesOffsetBefore={18}
      centeredSlides={false}
    >
      {operations.map((operation) => (
        <SwiperSlide
          className="h-auto w-8/12 _border _border-green-500 self-stretch _first:ml-6 _last:ml-6"
          style={{ height: "auto" }}
          key={operation.sys.id}
        >
          <div className="mx-0 self-stretch h-full" style={{}}>
            <OperationMediumCard key={operation.sys.id} entry={operation} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);
