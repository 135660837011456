import clsx from "clsx";
import Image from "next/image";

interface ILogo {
  name: string;
  path: string;
}
export const PartnerLogosListPrimary = () => {
  const logos = [
    {
      name: "ORSA",
      path: "orsa.png",
    },
    {
      name: "gpa",
      path: "gpa.png",
    },
    {
      name: "Gpa Sud",
      path: "gpa-sud.png",
    },
    {
      name: "Gpa Roissy",
      path: "gpa-roissy.png",
    },
  ] as ILogo[];
  return (
    <div className="grid grid-cols-2 xl:grid-cols-4 gap-8 xl:gap-12">
      {logos.map((logo: ILogo, index: number) => (
        <div
          key={`${logo.path}-${index}`}
          className="flex justify-center items-center"
        >
          <Image
            src={`/images/logos/partners/${logo.path}`}
            alt={logo.name}
            width={200}
            height={100}
            className={clsx(
              "grow object-contain",
              logo.path === "orsa.png" && "p-4"
            )}
          />
        </div>
      ))}
    </div>
  );
};

export const PartnerLogosListSecondary = () => {
  const logos = [
    {
      name: "Région Ile-de-France",
      path: "idf.png",
    },
    {
      name: "République Française",
      path: "rf.png",
    },
    {
      name: "Métropole Grand Paris",
      path: "metropole-grand-paris.png",
    },
    {
      name: "Choose Paris Region",
      path: "choose-paris-region.png",
    },
    {
      name: "EPF Ile-de-France",
      path: "epf-idf.png",
    },
    {
      name: "Société du Grand Paris",
      path: "societe-gpa.png",
    },
    {
      name: "Anah",
      path: "anah.png",
    },
    {
      name: "Banque des territoires",
      path: "banque-territoires.png",
    },
    {
      name: "ANR",
      path: "anr.png",
    },
    {
      name: "Institut Paris Region",
      path: "institut-paris-region.png",
    },
  ] as ILogo[];
  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-20 gap-y-12">
      {logos.map((logo: ILogo, index: number) => (
        <div
          className="flex justify-center items-center"
          key={`${logo.path}-${index}`}
        >
          <Image
            src={`/images/logos/partners/${logo.path}`}
            alt={logo.name}
            width={200}
            height={100}
            className="grow object-contain"
          />
        </div>
      ))}
    </div>
  );
};
