import {
  Construction,
  GraduationCap,
  LandPlot,
  TrainTrack,
  Unplug,
  Users,
} from "lucide-react";

interface IStat {
  number: string;
  description: string;
  icon: React.ReactNode;
}
export const StatsList = () => {
  const stats = [
    {
      number: "290",
      icon: <Users size={48} />,
      description: "Collaborateurs",
    },
    {
      number: "80",
      icon: <Construction size={48} />,
      description: "Opérations d'aménagement actives",
    },
    {
      number: "2,4 millions",
      icon: <LandPlot size={48} />,
      description: "de m2 à produire en 10 ans",
    },
  ] as IStat[];
  return (
    <div className="w-[calc(100%+3rem)] xl:w-[calc(100%+8rem)] -ml-6 xl:-ml-16 grid grid-cols-1 xl:grid-cols-3 gap-12 bg-brand-green text-white px-4 py-8 xl:px-10 xl:py-16 bg-line-pattern mb-8">
      {stats.map((stat: IStat, index: number) => (
        <div
          key={`${stat.number}-${index}`}
          className="space-y-2 flex flex-col items-center"
        >
          <div>{stat.icon}</div>
          <div className="text-3xl">{stat.number}</div>
          <p className="text-center">{stat.description}</p>
        </div>
      ))}
    </div>
  );
};

export const DurableStatsList = () => {
  const stats = [
    {
      number: "1/3",
      icon: <Unplug size={48} />,
      description:
        "des besoins énergétiques des quartiers conçus et réalisés par GPA sont couverts par des ENR",
    },
    {
      number: "47%",
      icon: <Construction size={48} />,
      description: "des déchets de déconstruction sont revalorisés",
    },
    {
      number: "83,8%",
      icon: <TrainTrack size={48} />,
      description:
        "de surfaces construites dans les opérations de Grand Paris Aménagement sont à proximité de transports en commun publics",
    },
    {
      number: "53 686 heures",
      icon: <GraduationCap size={48} />,
      description:
        "d’insertion professionnelle ont été générées par les chantiers des opérations de GPA en 2020",
    },
  ] as IStat[];
  return (
    <div className="w-[calc(100%+3rem)] xl:w-[calc(100%+8rem)] -ml-6 xl:-ml-16 grid grid-cols-1 xl:grid-cols-4 gap-12 bg-brand-green text-white px-4 py-8 xl:px-10 xl:py-16 bg-line-pattern">
      {stats.map((stat: IStat, index: number) => (
        <div
          key={`${stat.number}-${index}`}
          className="space-y-2 flex flex-col items-center"
        >
          <div>{stat.icon}</div>
          <div className="text-2xl">{stat.number}</div>
          <p className="text-center text-white/70 text-sm">
            {stat.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export const PartnerLogosListSecondary = () => {
  return <div>partnerLogosList Primary</div>;
};
