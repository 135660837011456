import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["EventBanner"] */ "/vercel/path0/src/components/banner/eventBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OperationMediumCard"] */ "/vercel/path0/src/components/card/operationMediumCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeOperationsCarousel"] */ "/vercel/path0/src/components/carousel/homeOperationsCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VisitorTypeNav"] */ "/vercel/path0/src/components/home/visitorTypeNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeCorpoArguments"] */ "/vercel/path0/src/components/texts/homeCorpoArguments.tsx");
