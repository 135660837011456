import * as React from "react";
import { gsap } from "gsap";
import { X } from "lucide-react";
import ReactPlayer from "react-player";

interface ModalProps {
  videoId: string;
  onClose: () => void;
}

const VideoModal: React.FC<ModalProps> = ({ videoId, onClose }) => {
  //   if (!entry) return null;

  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (modalRef.current) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 0.75, ease: "power3.out" }
      );
    }
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
      <div
        ref={modalRef}
        className="bg-white xl:rounded-lg shadow-lg p-0 w-12/12 md:w-1/2 lg:w-1/3 max-h-[100dvh] xl:max-h-[90vh] overflow-y-auto"
      >
        <div className="bg-brand-blue bg-line-pattern flex">
          <div className="shrink-0">
            <button onClick={onClose} className="p-4 text-white">
              <X />
            </button>
          </div>
        </div>
        {/* <div>React video player {videoId}</div> */}
        <div className="aspect-video">
          <ReactPlayer url={videoId} width={"100%"} />
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
