"use client";
import * as React from "react";
import { IProfile } from "@/types/profile";
import Image from "next/image";
import { MailPlus } from "lucide-react";
import { ProfileModal } from "../modal/profile";

export const ExpertsProfilesList = ({ profiles }: { profiles: IProfile[] }) => {
  const data = profiles.filter((profile: IProfile) => profile.dirZone);
  return (
    <div className="space-y-6 xl:space-y-20 relative">
      <div className="grid xl:grid-cols-3 gap-8">
        {data.map((profile: IProfile, index: number) => (
          <ExpertProfileItem
            key={`${profile.sys.id}-${index}`}
            profile={profile}
          />
        ))}
      </div>
    </div>
  );
};

const ExpertProfileItem = ({ profile }: { profile: IProfile }) => {
  const [isVideoOpen, setIsVideoOpen] = React.useState<boolean>(false);
  return (
    <>
      {isVideoOpen && (
        <ProfileModal profile={profile} onClose={() => setIsVideoOpen(false)} />
      )}
      <div
        onClick={() => setIsVideoOpen(true)}
        className="shadow-lg rounded-md group flex flex-col justify-between flex-grow cursor-pointer"
      >
        {profile.image && (
          <div className="overflow-hidden rounded-t-md relative">
            <Image
              className=" cursor-pointer group-hover:opacity-90 transition-all duration-150 group-hover:scale-105"
              src={`${profile.image.url}?w=400&h=500&fit=fill`}
              alt={profile.image.title}
              width={profile.image.width}
              height={profile.image.height}
            />
            <div className="absolute bottom-0 right-2 rounded-t-sm bg-white p-3">
              <MailPlus className="w-4 h-4" />
            </div>
          </div>
        )}
        <div className="space-y-4 mt-1 p-4 flex-1">
          <h3 className="text-xl font-bold text-brand-blue flex items-center gap-x-2">
            <span>
              {profile.firstname} {profile.lastname}
            </span>
          </h3>
          <div className="flex-1">
            {profile.position && (
              <p className="text-gray-700">{profile.position}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
