import { IPage } from "@/types/page";
import { IPost } from "@/types/post";

export const linkResolver = (entry: IPage | IPost) => {
  if (entry.__typename === "Page") {
    if (entry.slug === "etablissement") {
      return `/etablissement`;
    } else {
      return `/etablissement/${entry.slug}`;
    }
  }

  if (entry.__typename === "Post") {
    return `/post/${entry.slug}`;
  }

  return `/`;
};
