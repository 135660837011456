export interface ISocialNetworkLink {
  name: string;
  url: string;
  logoPath: string;
}

export const socialNetworkLinks: ISocialNetworkLink[] = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/grand-paris-amenagement/",
    logoPath: "linkedin.svg",
  },
  {
    name: "X",
    url: "https://x.com/gpamenagement",
    logoPath: "x.svg",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/gpamenagement/",
    logoPath: "instagram.svg",
  },
  // {
  //   name: "Facebook",
  //   url: "https://www.facebook.com/gpamenagement/",
  //   logoPath: "facebook.svg",
  // },

  {
    name: "Youtube",
    url: "https://www.youtube.com/channel/UCRpuGk0svqIcdoX48OLVCTQ",
    logoPath: "youtube.svg",
  },
  {
    name: "Ausha",
    url: "https://podcast.ausha.co/vous-etes-bien-urbain",
    logoPath: "ausha.svg",
  },
  // {
  //   name: "Contact",
  //   url: "/a-propos/nous-contacter/",
  //   logoPath: "contact.svg",
  // },
];
