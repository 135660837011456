"use client";
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "react-toastify";

const schema = z.object({
  email: z.string().email({ message: "Votre adresse semble erronée" }),
});

type FormData = z.infer<typeof schema>;

const NewsletterForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      const response = await fetch("/api/newsletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      toast.success("Votre inscription a bien été enregistrée.", {
        position: "bottom-left",
      });
      reset();
    } catch (error) {
      console.error("There was a problem with the subscription:", error);
      alert("Subscription failed. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex">
        <div className="border border-white">
          <input
            className="bg-gray-950 px-2 py-1.5 focus:outline-none active:outline-none active:bg-none focus:bg-none"
            id="email"
            type="email"
            placeholder="Votre adresse email"
            {...register("email")}
          />

          <button
            className="bg-white text-gray-900 items-stretch h-auto p-2"
            type="submit"
          >
            ok
          </button>
        </div>
      </div>
      {errors.email && (
        <p className="text-red-500 mt-4 text-sm">{errors.email.message}</p>
      )}
    </form>
  );
};

export default NewsletterForm;
