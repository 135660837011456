import { IProfile } from "@/types/profile";
import { BoardProfileCard } from "../card/profileCard";

export const ProfilesGridList = ({ profiles }: { profiles: IProfile[] }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
    {profiles.map((profile: IProfile) => (
      <BoardProfileCard key={profile.sys.id} profile={profile} />
    ))}
  </div>
);
