"use client";
import clsx from "clsx";
import { Check, X } from "lucide-react";
import { RequestCookie } from "next/dist/compiled/@edge-runtime/cookies";
import Link from "next/link";
import * as React from "react";

const CookieBanner = ({
  alreadyChosen,
}: {
  alreadyChosen: RequestCookie | undefined;
}) => {
  const [showBanner, setShowBanner] = React.useState(
    alreadyChosen ? false : true
  );

  const handleAccept = () => {
    document.cookie = `gpa-gdpr-cookie=accept; expires=${new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 365
    ).toString()}; path=/`;
    setShowBanner(false);
  };

  const handleDecline = () => {
    document.cookie = `gpa-gdpr-cookie=refuse; expires=${new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 365
    ).toString()}; path=/`;
    setShowBanner(false);
  };
  if (alreadyChosen || !showBanner) return null;

  return (
    <div
      className={clsx(
        "fixed bottom-0 w-full pointer-events-auto text-white z-40",
        showBanner ? "block" : "hidden"
      )}
    >
      <div className="bg-brand-dark bg-line-pattern-white shadow-xl bg-[length:320px_320px]">
        <div className="mx-auto lg:w-10/12 xl:w-9/12 p-6 xl:p-12">
          <div className="flex">
            <p className="text-xl font-semibold hidden lg:block pr-8">
              Cookies
            </p>
            <div>
              <div className="grid grid-cols-12 gap-4 lg:gap-8">
                <div className="col-span-12 lg:col-span-9">
                  <p>
                    En poursuivant votre navigation sur ce site, vous acceptez
                    l&apos;utilisation de cookies utilisés pour réaliser des
                    statistiques de visites.{" "}
                    <Link
                      href="/etablissement/mentions-legales/"
                      className="underline"
                    >
                      En savoir plus
                    </Link>
                  </p>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <div className="grid grid-cols-2 gap-3 lg:block lg:space-y-3">
                    <button
                      className="px-3 py-1 border-2 border-white rounded-xs flex items-center gap-x-2 w-full hover:bg-white hover:text-brand-dark order-2"
                      onClick={() => handleAccept()}
                    >
                      <Check size={16} />
                      <span>Accepter</span>
                    </button>
                    <button
                      className="px-3 py-1 border-2 border-white rounded-xs flex items-center gap-x-2 w-full hover:bg-white hover:text-brand-dark order-1"
                      onClick={() => handleDecline()}
                    >
                      <X size={16} />
                      <span>Refuser</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
